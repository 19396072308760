import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Card } from "react-bootstrap"

function ImageCard({ children, noBorder, ...ImgProps }) {
  const cardClass = noBorder ? "border-0" : undefined
  const cardBodyClass = noBorder ? "pb-0" : undefined

  return (
    <Card className={cardClass}>
      <Card.Img as={Img} {...ImgProps} />
      <Card.Body className={cardBodyClass}>{children}</Card.Body>
    </Card>
  )
}

ImageCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ImageCard
