import "../sass/index.scss"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../components/Header/Header"
import Seo from "../components/Seo"
import Footer from "../components/Footer"
import { ParallaxProvider } from "react-scroll-parallax/cjs"
import { FaPhone } from "react-icons/fa"
import companyInfo from "../data/companyInfo"

const MainLayout = ({ children, title, offset, description }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Seo title={title} description={description} />
      <Header />
      <ParallaxProvider>
        <main style={{ marginTop: offset && 73 }}>{children}</main>
        <Footer />
      </ParallaxProvider>
      <div className="d-md-none fixed-phone">
        <a
          href={`tel:${companyInfo.phone.match(/[0-9]+/g).join("-")}`}
          style={{ color: "inherit" }}
        >
          <FaPhone size={25} />
        </a>
      </div>
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  offset: PropTypes.bool,
}

export default MainLayout
