import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import ImageCard from "../components/ImageCard"
import { graphql, Link, useStaticQuery } from "gatsby"

function ServiceCards() {
  const data = useStaticQuery(graphql`
    query {
      residentialImage: file(relativePath: { eq: "residential.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      commercialImage: file(relativePath: { eq: "commercial.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      autoImage: file(relativePath: { eq: "auto.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      accessControlImage: file(relativePath: { eq: "access-control.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} sm={6} lg={3} className="mb-5 mb-lg-0">
          <ImageCard
            noBorder
            fluid={data.residentialImage.childImageSharp.fluid}
            alt="residential"
          >
            <Link to="/residential" className="text-decoration-none">
              <Button variant="primary" block>
                Residential
              </Button>
            </Link>
          </ImageCard>
        </Col>
        <Col xs={12} sm={6} lg={3} className="mb-5 mb-lg-0">
          <ImageCard
            noBorder
            fluid={data.commercialImage.childImageSharp.fluid}
            alt="commercial"
          >
            <Link to="/commercial" className="text-decoration-none">
              <Button variant="primary" block>
                Commercial
              </Button>
            </Link>
          </ImageCard>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <ImageCard
            noBorder
            fluid={data.autoImage.childImageSharp.fluid}
            alt="auto"
          >
            <Link to="/auto" className="text-decoration-none">
              <Button variant="primary" block>
                Auto
              </Button>
            </Link>
          </ImageCard>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <ImageCard
            noBorder
            fluid={data.accessControlImage.childImageSharp.fluid}
            alt="access-control"
          >
            <Link to="/access-control" className="text-decoration-none">
              <Button variant="primary" block>
                Access Control
              </Button>
            </Link>
          </ImageCard>
        </Col>
      </Row>
    </Container>
  )
}

export default ServiceCards
