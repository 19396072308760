import { useState } from "react"
import { SUBMIT_URL } from "../appConfig"

function useFormSubmit() {
  const [loading, setLoading] = useState(false)

  function submitForm(form) {
    setLoading(true)
    fetch(SUBMIT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then(() => {
        setLoading(false)
        alert("Successfully submitted your contact info")
      })
      .catch(() => {
        setLoading(false)
        alert("Problem submitting contact info")
      })
  }
  return [submitForm, loading]
}

export default useFormSubmit
