import React from "react"
import PropTypes from "prop-types"
import { Col, Container, Row } from "react-bootstrap"
import FreeQuoteCard from "../FreeQuoteCard"
import BackgroundImage from "gatsby-background-image"

function ContactHeading({ children, background }) {
  let sectionClass = "pt-0"
  if (background) {
    sectionClass += " text-white"
  }
  return (
    <section className={sectionClass} style={{ position: "relative" }}>
      {background && (
        <BackgroundImage
          fluid={[
            "linear-gradient(180deg, rgba(0, 0, 0, 0.76), transparent)",
            background,
          ]}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: "20%",
          }}
        />
      )}
      <Container>
        <Row className="justify-content-center pt-5">
          <Col xs={12} lg={7} xl={8} className="mb-5 mb-xl-0 mt-5">
            {children}
          </Col>
          <Col xs={12} lg={5} xl={4} style={{ maxWidth: 380 }} className="mt-5">
            <FreeQuoteCard />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

ContactHeading.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.any,
}

export default ContactHeading
