import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import {
  FaYelp,
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaYoutubeSquare,
} from "react-icons/fa"
import companyInfo from "../../data/companyInfo"
import { BBBIcon } from "../../icons"

function Footer() {
  return (
    <footer className="text-light">
      <section className="bg-secondary">
        <Container>
          <Row>
            <Col xs={12} md={4} className="text-center text-md-left my-5">
              <h4 className="mb-2">Contact Information</h4>
              <div>Serving Sacramento Area</div>
              <div>Phone: {companyInfo.phone}</div>
              <div>LCO #: {companyInfo.lco}</div>
            </Col>
            <Col
              xs={12}
              md={4}
              className="d-flex flex-column align-items-center mb-5 mt-md-5"
            >
              <div className="text-center text-md-left">
                <h4 className="mb-2">Site Map</h4>
                <div>
                  <Link to="/" className="text-light">
                    Home
                  </Link>
                </div>
                <div>
                  <Link to="/residential" className="text-light">
                    Residential
                  </Link>
                </div>
                <div>
                  <Link to="/commercial" className="text-light">
                    Commercial
                  </Link>
                </div>
                <div>
                  <Link to="/auto" className="text-light">
                    Auto
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              md={4}
              className="d-flex flex-column align-items-center mb-5 mt-md-5"
            >
              <div className="d-flex d-md-block flex-column align-items-center">
                <h4 className="mb-2">Social Media</h4>
                <div className="d-inline-flex flex-wrap align-items-center">
                  <a
                    target="_blank"
                    href="https://www.yelp.com/biz/swift-locksmith-sacramento-3"
                    className="text-secondary bg-light p-1 rounded mr-2"
                  >
                    <FaYelp style={{ width: 40 }} size={36} />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.bbb.org/us/ca/sacramento/profile/locksmith/swift-locksmith-1156-90023668"
                    className="text-white mr-1"
                  >
                    <BBBIcon style={{ width: 45 }} />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/Swiftlocksmith"
                    className="text-white mr-1"
                  >
                    <FaFacebookSquare size={50} />
                  </a>
                  <a
                    target="_blank"
                    href="https://twitter.com/SwiftLocksSac"
                    className="text-white mr-1"
                  >
                    <FaTwitterSquare size={50} />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/swiftlocksmith/"
                    className="text-white mr-1"
                  >
                    <FaInstagram size={50} />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCFRd1Ku-m5zCrPee17P-E0A/about"
                    className="text-white"
                  >
                    <FaYoutubeSquare size={50} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-dark py-2 text-center">
        <div>
          <small>Copyright 2019</small>
        </div>
        <small>Swift Locksmith | Designed By Rift IT Solutions</small>
      </section>
    </footer>
  )
}

export default Footer
