import React, { useRef, useLayoutEffect, useState } from "react"
import { Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Headroom from "headroom.js"
import { Button, Container, Form, Modal, Nav, Navbar } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import { useFormSubmit } from "../../hooks"

function Header() {
  const [submitForm, loading] = useFormSubmit()
  const headerRef = useRef()
  const [show, setShow] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      headerLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  useLayoutEffect(function initializeHeadroom() {
    if (headerRef.current) {
      let headroom = new Headroom(headerRef.current)
      headroom.init()
    }
  })

  return (
    <header ref={headerRef}>
      <Navbar expand="lg" variant="light" bg="light">
        <Container>
          <Navbar.Brand className="pb-0 mt-1">
            <Link to="/">
              <Img
                fixed={data.headerLogo.childImageSharp.fixed}
                alt="swift-locksmith-logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            <Nav as="ul" className="align-items-center">
              <li>
                <Nav.Link as={Link} to="/" activeClassName="active">
                  Home
                </Nav.Link>
              </li>
              <li>
                <Nav.Link as={Link} to="/residential" activeClassName="active">
                  Residential
                </Nav.Link>
              </li>
              <li>
                <Nav.Link as={Link} to="/commercial" activeClassName="active">
                  Commercial
                </Nav.Link>
              </li>
              <li>
                <Nav.Link as={Link} to="/auto" activeClassName="active">
                  Auto
                </Nav.Link>
              </li>
              <li>
                <Nav.Link as={Link} to="/access-control" activeClassName="active">
                  Access Control
                </Nav.Link>
              </li>
              <li className="ml-md-3">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => setShow(true)}
                >
                  Contact Us
                </Button>
              </li>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <div>
            <Modal.Title>How may we help you?</Modal.Title>
            <small className="text-muted">
              Available 24 hours, 7 days a week
            </small>
          </div>
        </Modal.Header>
        <Modal.Body
          as={Form}
          onSubmit={e => {
            e.preventDefault()
            const name = e.target.elements.name.value
            const email = e.target.elements.email.value
            const phone = e.target.elements.phone.value
            const comment = e.target.elements.comment.value
            submitForm({ name, phone, email, comment })
          }}
        >
          <Form.Row className="flex-column">
            <Col>
              <Form.Group>
                <Form.Control name="name" placeholder="Name" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control name="phone" placeholder="Phone" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control name="email" placeholder="Email" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="comment"
                  placeholder="Message"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Modal.Footer className="d-flex justify-content-end">
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </header>
  )
}

export default Header
