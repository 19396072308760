import React from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useFormSubmit } from "../../hooks"

function FreeQuoteCard() {
  const [submitForm, loading] = useFormSubmit()
  return (
    <Card className="border-0 bg-dark">
      <Form
        onSubmit={e => {
          e.preventDefault()
          const name = e.target.elements.name.value
          const email = e.target.elements.email.value
          const phone = e.target.elements.phone.value
          const comment = e.target.elements.comment.value
          submitForm({ name, phone, email, comment })
        }}
      >
        <Card.Body>
          <Card.Title as="h4" className="text-white text-center py-3">
            Get Your Free Quote
          </Card.Title>
          <Form.Group>
            <Form.Control name="name" placeholder="Name" />
          </Form.Group>
          <Form.Group>
            <Form.Control name="phone" placeholder="Phone" />
          </Form.Group>
          <Form.Group>
            <Form.Control name="email" placeholder="Email" />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows="3"
              name="comment"
              placeholder="Message"
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-center">
            <Button variant="primary" type="submit" disabled={loading}>
              Submit
            </Button>
          </Form.Group>
        </Card.Body>
      </Form>
    </Card>
  )
}

export default FreeQuoteCard
